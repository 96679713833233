import React, { useEffect, useState } from 'react';

import './index.css'


function reduzirTexto(text){

    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    
    // Pega o primeiro parágrafo
    const firstParagraph = doc.querySelector('p').textContent;

    let resume = "";
    let count = 0
    for (let caracter in firstParagraph){
        if(count < 100){
            resume += firstParagraph[caracter];
        }
        else{
            if(firstParagraph[caracter] !== " "){
                resume += firstParagraph[caracter];
            }
            else{
                resume += " ...";
                return resume;
            }
        }
        count++;
    }

    resume += " ...";

    return resume;

}



function Noticias() {
  const [noticia, setNoticia] = useState(null);

  useEffect(() => {
    fetch('https://parceiros.virtusocupacional.com.br/api/post')
      .then(response => response.json())
      .then(data => {setNoticia(data); console.log(data)})
      .catch(error => console.error('Erro ao buscar a notícia:', error));
  }, []);



  if (!noticia) {
    return <div>Carregando...</div>;
  }

  return (
    <div className='noticias'>
        <div className='flex justify-center'>
            <div className='border w-96'>
                <div className='flex justify-center'>
                    <img className='w-96 h-48' src={noticia[0].img[0].guid} alt="Notícia Imagem" />
                </div>
                <div>
                    <a href={noticia[0].posts.guid} className='tituloNoticia'><h1>{noticia[0].posts.post_title}</h1></a>
                    <a href={noticia[0].posts.guid} className='resumo'><p>{reduzirTexto(noticia[0].posts.post_content)}</p></a>
                    <a href={noticia[0].posts.guid} className='lerMais'>Ler mais...</a>
                </div>
            </div>
        </div>

    </div>
  );
}

export default Noticias;
